export const idlFactory = ({ IDL }) => {
  return IDL.Service({
    'acceptProposal' : IDL.Func(
        [IDL.Record({ 'proposeeName' : IDL.Text, 'weddingId' : IDL.Text })],
        [],
        [],
      ),
    'agreeToMarry' : IDL.Func([], [], []),
    'checkCompatibility' : IDL.Func(
        [IDL.Text, IDL.Text],
        [
          IDL.Opt(
            IDL.Record({
              'weaknesses' : IDL.Vec(IDL.Text),
              'strengths' : IDL.Vec(IDL.Text),
              'compatibility' : IDL.Text,
            })
          ),
        ],
        ['query'],
      ),
    'createWedding' : IDL.Func(
        [IDL.Record({ 'proposerName' : IDL.Text })],
        [IDL.Opt(IDL.Text)],
        [],
      ),
    'getAppVersion' : IDL.Func([], [IDL.Text], ['query']),
    'getCertificate' : IDL.Func(
        [IDL.Text],
        [
          IDL.Opt(
            IDL.Record({
              'id' : IDL.Text,
              'hadAt' : IDL.Nat,
              'isRejected' : IDL.Bool,
              'isPaid' : IDL.Bool,
              'partner1' : IDL.Record({
                'id' : IDL.Principal,
                'name' : IDL.Text,
                'ring' : IDL.Opt(
                  IDL.Record({
                    'tokenId' : IDL.Opt(IDL.Nat64),
                    'data' : IDL.Text,
                  })
                ),
                'wedding' : IDL.Text,
                'isAgreed' : IDL.Bool,
                'isWaiting' : IDL.Bool,
              }),
              'partner2' : IDL.Opt(
                IDL.Record({
                  'id' : IDL.Principal,
                  'name' : IDL.Text,
                  'ring' : IDL.Opt(
                    IDL.Record({
                      'tokenId' : IDL.Opt(IDL.Nat64),
                      'data' : IDL.Text,
                    })
                  ),
                  'wedding' : IDL.Text,
                  'isAgreed' : IDL.Bool,
                  'isWaiting' : IDL.Bool,
                })
              ),
            })
          ),
        ],
        ['query'],
      ),
    'getPartnerInfo' : IDL.Func(
        [IDL.Principal],
        [
          IDL.Opt(
            IDL.Record({
              'id' : IDL.Principal,
              'name' : IDL.Text,
              'ring' : IDL.Opt(
                IDL.Record({
                  'tokenId' : IDL.Opt(IDL.Nat64),
                  'data' : IDL.Text,
                })
              ),
              'wedding' : IDL.Text,
              'isAgreed' : IDL.Bool,
              'isWaiting' : IDL.Bool,
            })
          ),
        ],
        ['query'],
      ),
    'getWeddingInfoOf' : IDL.Func(
        [IDL.Principal],
        [
          IDL.Opt(
            IDL.Record({
              'id' : IDL.Text,
              'hadAt' : IDL.Nat,
              'isRejected' : IDL.Bool,
              'isPaid' : IDL.Bool,
              'partner1' : IDL.Record({
                'id' : IDL.Principal,
                'name' : IDL.Text,
                'ring' : IDL.Opt(
                  IDL.Record({
                    'tokenId' : IDL.Opt(IDL.Nat64),
                    'data' : IDL.Text,
                  })
                ),
                'wedding' : IDL.Text,
                'isAgreed' : IDL.Bool,
                'isWaiting' : IDL.Bool,
              }),
              'partner2' : IDL.Opt(
                IDL.Record({
                  'id' : IDL.Principal,
                  'name' : IDL.Text,
                  'ring' : IDL.Opt(
                    IDL.Record({
                      'tokenId' : IDL.Opt(IDL.Nat64),
                      'data' : IDL.Text,
                    })
                  ),
                  'wedding' : IDL.Text,
                  'isAgreed' : IDL.Bool,
                  'isWaiting' : IDL.Bool,
                })
              ),
            })
          ),
        ],
        ['query'],
      ),
    'pay' : IDL.Func([], [], []),
    'rejectProposal' : IDL.Func(
        [IDL.Record({ 'weddingId' : IDL.Text })],
        [],
        [],
      ),
    'setPartnerWaiting' : IDL.Func([], [], []),
    'setRing' : IDL.Func([IDL.Record({ 'ringBase64' : IDL.Text })], [], []),
    'updatePartnerName' : IDL.Func([IDL.Text], [], []),
  });
};
export const init = ({ IDL }) => { return []; };
