export enum FontWeights {
  Light = '300',
  Regular = '400',
  Medium = '500',
  SemiBold = '600',
  Bold = '700',
}

export enum FontFamily {
  Inter = 'Inter',
  Poppins = 'Poppins',
  PPMori = 'PPMori',
}

export type FontFamilyTypes = 'Inter' | 'Poppins' | 'PPMori';

export type TypographyVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body'
  | 'subtitle1'
  | 'subtitle2'
  | 'button'
  | 'label';

export type TypographyColors = 'black' | 'white' | 'gray' | 'error' | 'success' | 'accent' | 'grey' | 'transparent';
