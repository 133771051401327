export const Collections = [
  '16-bit',
  'magic',
  'classic',
  'nature',
  'superpower',
  'techno',
  'metalico',
  'ringcraft',
  '2046',
  'stars',
];
